<template>
    <v-container fluid class="mx-auto">
        <v-row>
            <v-col class="d-flex justify-end" cols="12">
                <v-switch v-model="paydrc" inset label="PayDRC Database"></v-switch>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col cols="12" lg="3">
                <v-combobox placeholder="choose a reference" v-model="typeSingle" clearable :items="items" solo rounded>
                </v-combobox>
            </v-col>
            <v-col cols="12" lg="3">
                <v-text-field v-model="reference" rounded solo></v-text-field>
            </v-col>
            <v-col cols="12" lg="3">
                <v-menu ref="menuUpdate" v-model="menuUpdate" :close-on-content-click="false"
                    :return-value.sync="updateDate" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="updateDate" label="Selectionner une date" prepend-icon="mdi-calendar"
                            readonly rounded solo v-bind="attrs" v-on="on" clearable large>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="updateDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuUpdate = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuUpdate.save(updateDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" lg="3">
                <v-btn block rounded large @click="searchSingle" :loading="loadingSingle">
                    <v-icon>mdi-magnify</v-icon>&nbsp;
                    search transaction
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="font-weight-bold text-button">Upload a transactions file</v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3">
                <v-combobox :items="items_file" clearable solo v-model="typeFile" rounded
                    placeholder="reference within the file">
                </v-combobox>
            </v-col>
            <v-col cols="12" lg="3">
                <v-file-input rounded solo v-model="files"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    placeholder="choose a file"></v-file-input>
            </v-col>
            <v-col cols="12" lg="3">
                <v-btn block rounded large @click="searchFile" :loading="loadingFile">
                    <v-icon>mdi-magnify</v-icon>&nbsp;
                    load transactions
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="col-lg-2 mt-2 d-flex justify-start">
                <v-btn rounded color="green">
                    <download-csv :data="dataItems"></download-csv>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="col-lg-7 mt-2 d-flex justify-end">
                <v-combobox :items="bulkStatus" v-model="bulkFileStatus" clearable solo rounded dense
                    placeholder="Choose a status to update all transactions"></v-combobox>
                &nbsp;
                &nbsp;
                <v-btn rounded color="info" :loading="bulkUpdateLoading" @click="bulkUpdate">
                    Update all transactions status
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="dataItems" :search="searchReference">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="searchReference"
                                    label="Search a merchant ID/Customer Details" class="mx-4 pt-4"
                                    append-icon="mdi-magnify"></v-text-field>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <p
                                    v-if="item.status === 'Failed' || item.status === 'Successful' || item.status === 'rejected'">
                                    Already Updated!
                                </p>
                                <div v-else>

                                    <v-btn class="text-caption" small rounded text color="info" :disabled="disabled"
                                        @click="update(item, true)">
                                        Successful</v-btn>
                                    <v-btn class="text-caption" small rounded text color="red" :disabled="disabled"
                                        @click="update(item, false)">
                                        Failed</v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-snackbar color="black" v-model="snackbar">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import readXlsxFile from 'read-excel-file'

import services from "../services/services";


export default {
    name: "Transactions",
    data: () => ({
        menuUpdate: false,
        bulkFileStatus: "",
        disabled: false,
        updateDate: "",
        paydrc: true,
        reference: "",
        bulkUpdateLoading: false,
        searchReference: "",
        typeSingle: "",
        typeFile: "",
        snackbar: false,
        files: [],
        text: "Transaction updated successful!",
        loadingSingle: false,
        loadingFile: false,
        headers: [
            { text: "Transaction ID", value: "transaction_id", align: "center" },
            { text: "Transaction Reference", value: "transaction_reference", align: "center" },
            { text: "Merchant Reference", value: "merchant_reference", align: "center" },
            { text: "Merchant", value: "merchant", filterable: false, align: "center" },
            { text: "Telco Reference", value: "operator_reference", align: "center" },
            { text: "Telecom Operator", value: "operator", filterable: false, align: "center" },
            { text: "Amount", value: "amount", filterable: false, align: "center" },
            { text: "Currency", value: "currency", filterable: false, align: "center" },
            { text: "Customer Details", value: "customer_details", align: "center" },
            { text: "Action", value: "action", filterable: false, align: "center" },
            { text: "Status", value: "status", filterable: false, align: "center" },
            { text: "Created At", value: "created_at", filterable: false, align: "center" },
            { text: "Updated At", value: "updated_at", filterable: false, align: "center" },
            { text: "Choose Final Status", value: "actions", align: "center" },
        ],
        dataItems: [
        ],
        bulkStatus: [
            "Failed",
            "Successful"
        ],
        items_file: [
            "thirdyparty_reference",
            "paydrc_reference",
            "switch_reference"
        ]
    }),
    methods: {
        async update(item, status) {
            this.disabled = true

            const data = {
                "merchant_reference": item.merchant_reference,
                "transaction_reference": item.transaction_reference,
                "paydrc": this.paydrc,
                "status": status
            }

            const response = await services.update_transactions(data)
            if (response.success === true) {
                this.snackbar = true
            }
            else {
                this.text = "Failed to update transaction, please contact admin!"
                this.snackbar = true
            }

            if (status === true) {
                this.loadingSuccess = false
            }
            else {
                this.loadingFalse = false
            }
            this.disabled = false

        },
        async bulkUpdate() {
            if (!["Failed", "Successful"].includes(this.bulkFileStatus)) {
                return;
            }

            this.bulkUpdateLoading = true
            let data = { data: [] }
            let response = {}

            for (let i = 0; i < this.dataItems.length; i++) {
                const transaction = this.dataItems[i];

                data.data.push({
                    "merchant_reference": transaction.merchant_reference,
                    "transaction_reference": transaction.transaction_reference,
                    "paydrc": this.paydrc,
                    "status": this.bulkFileStatus
                })

            }

            response = await services.bulk_update_transactions(data)
            this.bulkUpdateLoading = false

            if (response.success === true) {
                this.snackbar = true
            }
            else {
                this.text = "Failed to update transactions, please contact admin!"
                this.snackbar = true
            }
        },
        async searchSingle() {
            this.loadingSingle = true
            const data = {
                "reference": this.reference,
                "paydrc": this.paydrc,
                "type": this.typeSingle,
                "date": this.updateDate ? this.updateDate : ""
            }
            const response = await services.search_transactions(data)
            this.dataItems = response.transactions
            this.loadingSingle = false
        },
        async searchFile() {
            this.loadingFile = true
            readXlsxFile(this.files).then(async (rows) => {
                let temp = []
                if (this.typeFile === "thirdyparty_reference") {
                    let data = { data: [] }
                    for (let i = 0; i < rows.length; i++) {
                        data.data.push({
                            "reference": rows[i][0],
                            "paydrc": true,
                            "type": "merchant reference",
                            "date": ""
                        })
                    }
                    const response = await services.bulk_transactions(data)
                    temp = response.transactions
                }
                else if (this.typeFile === "paydrc_reference") {
                    let data = { data: [] }
                    for (let i = 0; i < rows.length; i++) {
                        data.data.push({
                            "reference": rows[i][0],
                            "paydrc": true,
                            "type": "paydrc reference",
                            "date": ""
                        })
                    }
                    const response = await services.bulk_transactions(data)
                    temp = response.transactions
                }
                else if (this.typeFile === "switch_reference") {
                    let data = { data: [] }
                    for (let i = 0; i < rows.length; i++) {
                        data.data.push({
                            "reference": rows[i][0],
                            "paydrc": true,
                            "type": "switch reference",
                            "date": ""
                        })
                    }
                    const response = await services.bulk_transactions(data)
                    temp = response.transactions
                }
                this.dataItems = temp
                this.loadingFile = false
            })
        }
    },
    computed: {
        items: function () {
            if (this.paydrc === true) {
                return [
                    "merchant reference",
                    "paydrc reference",
                    "telco reference",
                    "customer number"]
            }
            else {
                return [
                    "switch reference",
                    "telco reference",
                    "customer number"
                ]
            }
        }
    }
}
</script>


<style scoped>
</style>